// Core
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { fm } from 'utils';
import { isDataValidOrDash } from './utils/isDataValidOrDash';
import { renderPersonalData } from './utils/renderPersonalData';
import { renderSoleProprietorData } from './utils/renderSoleProprietorData';
import update from 'immutability-helper';
import { withUI, withRoot } from 'hocs';
import { compose } from 'recompose';
import { statusLocalization } from './utils/statusLocalization';

// UI
import AccountRoles from './AccountRoles/AccountRoles';
import AccountPersonalData from './AccountPersonalData/AccountPersonalData';
import AccountPersonalDataCompany from './AccountPersonalDataCompany/AccountPersonalDataCompany';
import AccountContactData from './AccountContactData/AccountContactData';
import AccountFiles from './AccountFiles/AccountFiles';
import AccountContracts from './AccountContracts/AccountContracts';
import Field from 'pages/adminV2/components/Field/Field';
import { Loading } from 'components';
import { Label } from 'components/Labels';
import PaymentsWithdrawal from './PaymentsWithdrawal/PaymentsWithdrawal';
import RoundedButton from 'components/Buttons/RoundButton/RoundButton';
import PrivilegeModal from './PrivilegeModal/PrivilegeModal';

// Constants
import accountCompletionSteps from 'constants/accountCompletionSteps';
import accountTypes from 'constants/accountTypes';

// Styles
import styles from './AccountData.module.css';

function AccountData(props) {
	const {
		getCountryById,
		setAccountStep,
		switchToPhysical,
		getMvpUserData,
		getSignupsUserData,
		isAdminRedirectLocale,
	} = useContext(RootContext);
	const { isPaymentsEnabled } = useContext(UIContext);

	const { isSignup } = props;
	const [modalActive, setModalActive] = useState(false);
	const [modalType, setModalType] = useState('');
	const [loading, setLoading] = useState(true);
	const [account, setAccount] = useState({});
	const [additionalContracts, setAdditionalContracts] = useState([]);
	const [moderationStatus, setModerationStatus] = useState({});
	const [prevCompletionStep, setPrevCompletionStep] = useState(null);
	const [completionStep, setCompletionStep] = useState(null);
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);
	const [additionalSubmitBtnData, setAdditionalSubmitBtnData] = useState({});
	const [warnBlockNum, setWarnBlockNum] = useState(0);
	const [warnBlockNumAddContract, setWarnBlockNumAddContract] = useState(0);

	const updateCompletionStep = (
		account,
		completionStep,
		newModerationStatus
	) => {
		switch (completionStep) {
			case accountCompletionSteps.PENDING:
			case accountCompletionSteps.APPROVED:
			case accountCompletionSteps.DECLINED_DOCUMENTS:
				if (Object.keys(account.files).length > 0) {
					const arr = Object.keys(account.files)
						.map((key) => [
							newModerationStatus[key].valid,
							newModerationStatus[key].quality,
						])
						.flat();
					const result = arr.reduce((acc, mark) => acc && mark);

					if (result) {
						setCompletionStep(accountCompletionSteps.APPROVED);
					} else {
						setCompletionStep(accountCompletionSteps.DECLINED_DOCUMENTS);
					}
				}
				break;
			case accountCompletionSteps.APPROVED_CONTRACTS:
			case accountCompletionSteps.DECLINED_CONTRACTS:
			case accountCompletionSteps.PERSONAL_AREA:
			case accountCompletionSteps.REPERTOIRE_APPLY:
			case accountCompletionSteps.PENDING_CONTRACT:
				if (Object.keys(account.contracts).length > 0) {
					const arr = Object.keys(account.contracts)
						.map((key) => [
							newModerationStatus[key].valid,
							newModerationStatus[key].signature,
							newModerationStatus[key].quality,
						])
						.flat();

					const result = arr.reduce((acc, mark) => acc && mark);

					if (result) {
						setCompletionStep(accountCompletionSteps.APPROVED_CONTRACTS);
					} else {
						setCompletionStep(accountCompletionSteps.DECLINED_CONTRACTS);
					}
				}
				break;
			default:
				return;
		}
	};

	const switchSignToPhysical = (id) => {
		setLoading(true);

		switchToPhysical(id)
			.then((response) => {
				account.step = accountCompletionSteps.SIGN_PHYSICAL;
				account['allow_sign_change'] = false;
				setAccount({ ...account });
			})
			.catch((error) => {
				console.error(
					'switchToPhysical - api - error.response: ',
					error.response
				);
				return alert(`Не удалось изменить тип подписания`);
			})
			.finally(() => setLoading(false));
	};

	const toggleModerationStatus = (field, status) => (event) => {
		const value = event.currentTarget.checked;
		const newModerationStatus = update(moderationStatus, {
			[field]: { [status]: { $set: value } },
		});
		setModerationStatus(newModerationStatus);
		updateCompletionStep(account, completionStep, newModerationStatus);
	};

	const handleSubmit = () => {
		if (isSubmitPressed) return;
		setIsSubmitPressed(true);

		if (additionalContracts.length) {
			setAccountStep(props.id, {
				completion_step: completionStep,
				moderation_status: moderationStatus,
			})
				.then((res) => {
					statusLocalizationAdditional()
						? setAdditionalSubmitBtnData({
								message: fm('rod.admin.account.files.approved'),
								disabled: true,
						  })
						: setAdditionalSubmitBtnData({
								message: fm('rod.admin.account.files.sent'),
								disabled: true,
						  });
				})
				.catch((err) => {
					console.info('Error:', err);
				});
		} else {
			setAccountStep(props.id, {
				completion_step: completionStep,
				moderation_status: moderationStatus,
			})
				.then((res) => {
					setPrevCompletionStep(completionStep);
				})
				.catch((err) => {
					setPrevCompletionStep(completionStep); //todo
				});
		}
	};

	useEffect(() => {
		if (isSignup) {
			getSignupsUserData(props.id).then((res) => console.info('signup', res));
		}

		getMvpUserData(props.id).then((res) => {
			setAccount(res);
			const additional = Object.keys(res.contracts).filter(
				(key) => res.contracts[key].additional
			);
			if (additional.length) setAdditionalContracts(additional);

			setLoading(false);
			setModerationStatus(res.moderation_status);
			setCompletionStep(res.step);
			setPrevCompletionStep(res.step);
			updateCompletionStep(res, res.step, res.moderation_status);
		});
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Loading />;
	}

	const isDocumentsControlsEnabled =
		prevCompletionStep === accountCompletionSteps.PENDING;
	const isContractsControlsEnabled =
		prevCompletionStep === accountCompletionSteps.PENDING_CONTRACT;

	const statusLocalizationAdditional = () => {
		let count = [];
		Object.keys(account.contracts).map((key) => {
			if (account.contracts[key].additional) {
				if (
					!moderationStatus[key]['quality'] ||
					!moderationStatus[key]['signature'] ||
					!moderationStatus[key]['valid']
				) {
					count.push(moderationStatus[key]);
				}
			}
		});
		return !count.length ? true : false;
	};

	const isAdditionalBtnDisabled = () => {
		const isSigned = [];
		Object.keys(account.contracts).map((key) => {
			if (account.contracts[key].additional) {
				if (account.contracts[key].additional_contracts_signed) {
					isSigned.push(account.contracts[key]);
				}
			}
		});
		return !isSigned.length || additionalSubmitBtnData.disabled ? true : false;
	};

	const handleChangeLocale = (e) => {
		if (e.target.checked) {
			account.redirect = 1;
			setAccount({ ...account });
		} else {
			account.redirect = 0;
			setAccount({ ...account });
		}
		isAdminRedirectLocale(props.id, account.redirect)
			.then()
			.catch((err) => {
				console.error('Error:', err);
			});
	};

	const handleWarningBlockShow = (e, key) => {
		const isAddintionalContractSelected =
			e.currentTarget.getAttribute('data-name').length === 3;
		const isMainApproved = additionalContracts.length > 0;

		if (isMainApproved && !isAddintionalContractSelected) {
			return;
		}

		if (
			!account.contracts[key].additional_contracts_signed &&
			isAddintionalContractSelected &&
			!moderationStatus[key]['signature']
		) {
			setWarnBlockNumAddContract(e.currentTarget.getAttribute('data-name'));
			setTimeout(() => {
				setWarnBlockNumAddContract('0');
			}, 2000);
		} else if (
			!isMainApproved &&
			!isContractsControlsEnabled &&
			!isAddintionalContractSelected &&
			!moderationStatus[key]['signature']
		) {
			setWarnBlockNum(e.currentTarget.getAttribute('data-name'));
			setTimeout(() => {
				setWarnBlockNum('0');
			}, 2000);
		}
	};

	const setModalData = (type, modal = true) => {
		setModalType(type);
		setModalActive(modal);
	};

	return (
		<Container fluid className={styles.grid}>
			<Row>
				<Col sm={10} offset={{ sm: 1 }} className={styles.container}>
					<div className={styles.Page}>
						<section className={styles.Page__title}>
							<div className={styles.title}>
								{account.personal_data ? account.personal_data.title : ''}
							</div>
							<div className={styles.status}>
								<span>
									{account.personal_data ? (
										<>
											{fm(`rod.admin.account.status_title`)}:{' '}
											{prevCompletionStep &&
												statusLocalization(prevCompletionStep)}
										</>
									) : (
										''
									)}
								</span>
							</div>
						</section>
						<section>
							<div className={styles.HeaderLine}>
								<Label
									text={fm('rod.admin.user.title')}
									font="--gilroy-Medium-32"
								/>
								<RoundedButton
									onClick={() => {
										setModalData('user');
									}}
									classes={styles.actionButton}
								>
									<FormattedMessage id={'rod.admin.account.privilege'} />
								</RoundedButton>
							</div>

							{account.user_data && (
								<>
									<Row>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.personal_data.first_name')}
												value={isDataValidOrDash(account.user_data.name)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.email')}
												value={isDataValidOrDash(account.user_data.email)}
											/>
										</Col>
										<Col sm={4}>
											<Field
												title={fm('rod.admin.account.user_data.phone')}
												value={isDataValidOrDash(account.user_data.phone)}
											/>
										</Col>
									</Row>
								</>
							)}

							<div className={styles.HeaderLine}>
								<Label
									text={fm('rod.admin.account')}
									font="--gilroy-Medium-32"
								/>
								<RoundedButton
									classes={styles.actionButton}
									onClick={() => {
										setModalData('account');
									}}
								>
									<FormattedMessage id={'rod.admin.account.privilege'} />
								</RoundedButton>
							</div>

							{account.roles && <AccountRoles roles={account.roles} />}
							{account.personal_data && (
								<AccountPersonalData account={account} />
							)}
						</section>

						{account.personal_data &&
							account.personal_data.business_type === accountTypes.PERSONAL && (
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.account.personal_information')}
											font="--gilroy-Medium-32"
										/>
									</div>
									{renderPersonalData(account)}
								</section>
							)}

						{account.personal_data &&
							account.personal_data.business_type ===
								accountTypes.PERSONAL_ENTREPRENEUR && (
								<section>
									<div className={styles.HeaderLine}>
										<Label
											text={fm('rod.admin.account.sole_proprietor_data')}
											font="--gilroy-Medium-32"
										/>
									</div>
									{renderSoleProprietorData(account, getCountryById)}
								</section>
							)}

						{account.personal_data &&
							account.personal_data.business_type === accountTypes.COMPANY && (
								<AccountPersonalDataCompany account={account} />
							)}

						{account.contact_data && (
							<AccountContactData
								account={account}
								handleChangeLocale={handleChangeLocale}
							/>
						)}

						{account.files && (
							<AccountFiles
								account={account}
								moderationStatus={moderationStatus}
								isDocumentsControlsEnabled={isDocumentsControlsEnabled}
								handleSubmit={handleSubmit}
								toggleModerationStatus={toggleModerationStatus}
								completionStep={completionStep}
								prevCompletionStep={prevCompletionStep}
							/>
						)}

						{account.societies && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.societies')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<table className={styles.Table}>
									<thead>
										<tr>
											<th>
												{fm('rod.admin.account.societies.author_society')}
											</th>
											<th>{fm('rod.admin.account.societies.date')}</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(account.societies) &&
											account.societies.length > 0 &&
											account.societies.map((society) => (
												<tr>
													<td>
														{society.title ||
															society.title_ru ||
															society.title_en}
													</td>
													<td>
														{society.mebership_since
															? society.mebership_since
															: ''}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</section>
						)}

						{account.pseudonyms && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.pseudonyms.names_identifiers')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<table className={styles.Table}>
									<thead>
										<tr>
											<th>{fm('rod.admin.account.pseudonyms.identifier')}</th>
											<th>IPI</th>
											<th>ISNI</th>
										</tr>
									</thead>
									<tbody>
										{account.pseudonyms.map((pseudonym) => (
											<tr>
												<td>
													{pseudonym.title ||
														pseudonym.title_ru ||
														pseudonym.title_en}
												</td>
												<td>{isDataValidOrDash(pseudonym.ipi_name_number)}</td>
												<td>{isDataValidOrDash(pseudonym.isni)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</section>
						)}

						{account.payment_info && (
							<section>
								<div className={styles.HeaderLine}>
									<Label
										text={fm('rod.admin.account.payment.data')}
										font="--gilroy-Medium-32"
									/>
								</div>
								<Field
									title={fm('rod.admin.account.payment.method')}
									value={
										account.payment_info.type &&
										account.payment_info.type === 'bank_account'
											? fm('rod.admin.account.payment.bank_data')
											: account.payment_info.type
											? 'Paypal'
											: '\u2212'
									}
								/>
								{account.payment_info.type === 'bank_account' && (
									<React.Fragment>
										<Field
											title={fm('rod.admin.account.payment.beneficiary_name')}
											value={isDataValidOrDash(
												account.payment_info.beneficiary_name
											)}
										/>
										<Field
											title={fm('rod.admin.account.payment.bank_account_name')}
											value={isDataValidOrDash(
												account.payment_info.bank_account_name
											)}
										/>
										<Row>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_name')}
													value={isDataValidOrDash(
														account.payment_info.bank_name
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.swift_bic')}
													value={isDataValidOrDash(
														account.payment_info.swift_code
													)}
												/>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_address')}
													value={isDataValidOrDash(
														account.payment_info.bank_address
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm('rod.admin.account.payment.bank_country')}
													value={
														account.payment_info.bank_country
															? getCountryById(
																	account.payment_info.bank_country
															  )
															: '\u2212'
													}
												/>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Field
													title={fm(
														'rod.admin.account.payment.correspondent_account'
													)}
													value={isDataValidOrDash(
														account.payment_info.bank_correspondent_account
													)}
												/>
											</Col>
											<Col sm={6}>
												<Field
													title={fm(
														'rod.admin.account.payment.correspondent_account_swift'
													)}
													value={isDataValidOrDash(
														account.payment_info
															.bank_correspondent_account_swift_code
													)}
												/>
											</Col>
										</Row>
									</React.Fragment>
								)}
								{account.payment_info.type === 'paypal' && (
									<React.Fragment>
										<Field
											title={fm('rod.admin.account.payment.paypal_account')}
											value={isDataValidOrDash(
												account.payment_info.paypal_accoun
											)}
										/>
									</React.Fragment>
								)}
							</section>
						)}

						{account.contracts && (
							<AccountContracts
								account={account}
								additionalContracts={additionalContracts}
								switchSignToPhysical={switchSignToPhysical}
								warnBlockNum={warnBlockNum}
								warnBlockNumAddContract={warnBlockNumAddContract}
								isContractsControlsEnabled={isContractsControlsEnabled}
								isSubmitPressed={isSubmitPressed}
								moderationStatus={moderationStatus}
								toggleModerationStatus={toggleModerationStatus}
								prevCompletionStep={prevCompletionStep}
								statusLocalizationAdditional={statusLocalizationAdditional}
								handleSubmit={handleSubmit}
								isAdditionalBtnDisabled={isAdditionalBtnDisabled}
								additionalSubmitBtnData={additionalSubmitBtnData}
								isDocumentsControlsEnabled={isDocumentsControlsEnabled}
								handleWarningBlockShow={handleWarningBlockShow}
								completionStep={completionStep}
							/>
						)}

						{isPaymentsEnabled &&
							((account.payments && account.payments.length > 0) ||
								(account.royaltiesInvoice &&
									!account.royaltiesInvoice.startsWith('0'))) && (
								<PaymentsWithdrawal
									payments={account.payments}
									royaltiesInvoice={account.royaltiesInvoice}
								/>
							)}

						{account.sms &&
							account.sms.payment &&
							account.sms.payment.length > 0 && (
								<React.Fragment>
									<Field
										title={fm('rod.verify_sms.placeholder')}
										value={isDataValidOrDash(account.sms.payment)}
									/>
								</React.Fragment>
							)}
					</div>
				</Col>
			</Row>
			{modalActive && (
				<PrivilegeModal
					active={modalActive}
					setActive={setModalActive}
					account={account}
					type={modalType}
				/>
			)}
		</Container>
	);
}

export default compose(withUI, withRoot)(AccountData);
