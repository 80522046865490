import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

import Tabs from './Tabs/Tabs';
import ContentPage from './ContentPage/ContentPage';
import MetadataDetail from '../MetadataDetail/MetadataDetail';

import styles from './RecordingPage.module.css';

const RecordingPage = () => {
	const { accountId } = useContext(AuthContext);
	const { params } = useRouteMatch();
	const { pathname } = useLocation();
	const { showBackNavi, hideBackNavi } = useContext(UIContext);
	const history = useHistory();
	const [tab, setTab] = useState('recording');

	const handleClickTab = (tab) => {
		switch (tab) {
			case 'recording':
				setTab('recording');
				history.push(
					`/accounts/${accountId}/statistic/trends/recording/${params.id}`
				);
				break;
			case 'audience':
				setTab('audience');
				history.push(
					`/accounts/${accountId}/statistic/trends/recording/${params.id}/audience`
				);
				break;
			default:
				setTab('recording');
				history.push(
					`/accounts/${accountId}/statistic/trends/recording/${params.id}`
				);
		}
	};

	useEffect(() => {
		setTab(params.tab ?? 'recording');
		showBackNavi(
			'',
			() => {
				history.goBack();
				setTab(params.tab);
			},
			null
		);
		return () => {
			hideBackNavi();
		};
	}, []);

	useEffect(() => {
		setTab(params.tab ?? 'recording');
	}, [pathname]);

	return (
		<div>
			<Tabs handleClickTab={handleClickTab} tab={tab} />
			<div className={styles.wrapperMetadata}>
				<MetadataDetail
					accountId={accountId}
					params={params}
					typePage="recording"
				/>
			</div>
			<ContentPage accountId={accountId} tab={tab} />
		</div>
	);
};

export default RecordingPage;
