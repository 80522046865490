// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

import emptyDataTrends from 'images/trends/emptyDataTrends.svg';

// Styles
import styles from './EmptyData.module.css';

const TopOutletsGraphEmpty = () => {
	return (
		<div className={styles.emptyData}>
			<div className={styles.icon}>
				<img src={emptyDataTrends} alt="Empty" width={40} height={40} />
			</div>
			<h4 className={styles.title}>
				<FormattedMessage id={'rod.statistic.trends.empty-data'} />
			</h4>
		</div>
	);
};

export default TopOutletsGraphEmpty;
