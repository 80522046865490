const codeFormattedOutlets = {
	apple: 'Apple Music',
	spotify: 'Spotify',
	zvuk: 'Zvuk (СберЗвук)',
	vk: 'VK music',
	ok: 'Одноклассники',
	yandex: 'Yandex music',
	deezer: 'Deezer',
	youtube: 'YouTube',
	tiktok: 'TikTok',
	facebook: 'Facebook',
	instagram: 'Instagram',
	others: 'Others',
};

export default codeFormattedOutlets;
