import React from 'react';

import styles from './ContentPage.module.css';
import Release from './Release/Release';
import TopRecordingsDetail from '../../TopRecordingsDetail/TopRecordingsDetail';
import Audience from '../../Audience/Audience';

const ContentPage = ({ accountId, tab }) => {
	const renderScreen = (tab, accountId) => {
		switch (tab) {
			case 'release':
				return <Release accountId={accountId} />;
			case 'tracks':
				return (
					<TopRecordingsDetail accountId={accountId} typePage={'release'} />
				);
			case 'audience':
				return <Audience accountId={accountId} typePage={'release'} />;
			default:
				return <Release accountId={accountId} />;
		}
	};

	return <div className={styles.wrapper}>{renderScreen(tab, accountId)}</div>;
};

export default ContentPage;
