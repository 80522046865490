import React from 'react';

import DatesBlockNew from 'material-design/Statistic/StatisticCategory/StatisticByDatesSection/DatesBlockNew/DatesBlockNew';

import CountriesFilter from './CountriesFilter/CountriesFilter';
import FilterOutlets from 'material-design/Statistic/Trends/TrendsFilters/FilterOutlets/FilterOutlets';
import styles from './FiltersDetail.module.css';

const FiltersDetail = ({
	accountId,
	handleCheckedOutlets,
	lang,
	changeField,
	statisticData,
	errors,
	handleCheckedPeriod,
	setApplyDate,
	applyDateFilter,
	setIsLastCalendar,
	handleCheckedCountry,
	handleCloseFilterOutlets,
	isAllLoading,
}) => {
	return (
		<div
			className={`${styles.wrapperFilters} ${
				isAllLoading ? styles.disabled : {}
			}`}
		>
			<div className={styles.inputOutlets}>
				<FilterOutlets
					handleCheckedOutlets={handleCheckedOutlets}
					lang={lang}
					handleCloseFilterOutlets={handleCloseFilterOutlets}
				/>
			</div>
			<div className={styles.datePicker}>
				<DatesBlockNew
					accountId={accountId}
					changeField={changeField}
					statisticData={statisticData}
					errors={errors}
					handleCheckedPeriod={handleCheckedPeriod}
					setApplyDate={setApplyDate}
					applyDateFilter={applyDateFilter}
					setIsLastCalendar={setIsLastCalendar}
				/>
			</div>
			<div className={styles.inputCountries}>
				<CountriesFilter
					accountId={accountId}
					handleCheckedCountries={handleCheckedCountry}
					lang={lang}
				/>
			</div>
		</div>
	);
};

export default FiltersDetail;
